import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Header from "../components/header"
import NewsletterForm from "../components/newsletter-form"

export default class SimplePage extends React.Component<any, any> {
  render() {
    const page = this.props.data.markdownRemark
    const title = page.frontmatter.title

    return (
      <Layout>
        <SEO title={title} />
        <Header />
        <main className="content blog-post">
          <section className="content__row content__row--bleed static-header__header">
            <h1 className="static-header__title content__row">{title}</h1>
            <div className="content__row static-header__content"></div>
          </section>

          <section className="content__row __section">
            <div className="__section-content-container">
              <div className="faq__answer">
                <br />
                <div dangerouslySetInnerHTML={{ __html: page.html }} />
                <br />
                <NewsletterForm />
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
